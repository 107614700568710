/* Reservation.css */
.resa-container {
    background-color: #fff;
    padding: 20px;
    margin: 30px auto;
    min-width: 60%;
    max-width: 80%;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
  }
  
  .resa-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  label {
    margin-bottom: 10px;
    color: #666;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  textarea {
    width: 95%;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    height: 100px;
    resize: vertical; /* Permet à l'utilisateur de changer la taille verticalement */
  }
  
  .btn-submit {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-submit:hover {
    background-color: #45a049;
  }

@media (max-width: 600px) {
  .resa-container {
    padding: 10px; /* Reduced padding */
    margin: 10px auto;
    min-width: 90%; /* More appropriate for smaller screens */
    max-width: 95%;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  textarea {
    width: 90%; /* Full width for smaller screens */
    padding: 10px; /* Larger padding for easier interaction */
  }

  .btn-submit {
    padding: 12px 24px; /* Larger button on smaller screens */
  }
}
  