.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #212429ba;
  color: antiquewhite;
  padding-top: 85px;
}

@media (max-width: 600px) {
  .App {
    padding-top: 140px;
  }
}