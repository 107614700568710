.carousel {
    width: 100%;
    position: relative;
    margin: auto;
    overflow: hidden;
    height: 500px; /* Hauteur fixe pour empêcher les changements de taille */
}

.carousel-images {
    position: relative;
    width: 100%;
    height: 100%;
}

.carousel-images img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garantit que les images couvrent bien l'espace sans être déformées */
    transition: opacity 1s ease-in-out; /* Transition douce */
    opacity: 0;
}

.carousel-images img.active {
    opacity: 1; /* Rend l'image active visible */
}

.btn-carousel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    z-index: 100;
}

.btn-carousel:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel button:first-of-type {
    left: 10px;
}

.carousel button:last-of-type {
    right: 10px;
}
